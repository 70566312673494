import React from 'react'


const ContactForm = () =>{
    return (
        <div className="contact" id="contact">
        <div className="contact-info">
            <div className="contact-form">
                <form id="contact" name="contactoppasesoria" method="POST" data-netlify="true">
                    <input type="hidden" name="form-name" value="contactoppasesoria" />
                    <div className="row">
                        <div className="col">
                            <input type="text" name="nombre" id="nombre" placeholder="Nombre" required />
                        </div>
                        <div className="col">
                            <input type="text" name="empresa" id="empresa" placeholder="Empresa" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <input type="email" name="email" id="email" placeholder="E-mail" required />
                        </div>
                        <div className="col">
                            <input type="phone" name="telefono" id="telefono" placeholder="Teléfono" required />
                        </div>
                    </div>
                    <div className="row">
                        <textarea name="mensaje" id="mensaje" cols="30" rows="10" placeholder="Mensaje" type="text" required></textarea>
                    </div>
                    <p style={{textAlign: "center"}}>
                        <input type="hidden" name="_subject" value="Contact Form Submission" />
                        <input type="submit" value="Enviar" className="button" />
                    </p>
                </form>
            </div>
        </div>
    </div>	

    )
}

export default ContactForm