import React from "react"
import { Link } from "gatsby"

import ContactForm from './contact'
const Footer = () => (
  <footer className="footer-services" name="contacto">
	  <section className="info footer-info">
        <div className="container">
            <h1>¿Necesitas más información?</h1>
            <h3>Envíanos un mensaje</h3>
        </div>
		</section>
		<ContactForm />
		<div className="footer-footer">
			<div className="container">
			<div className="newsteller">
				<h3>Suscríbete</h3>
				<input type="email" name="email" id="email" placeholder="E-mail" required style={{width: 250,textAlign:"center",margin:"0 auto",marginBottom:20}}/>
				<input type="submit" value="Suscríbete" className="button" style={{width: 250,textAlign:"center",margin:"0 auto",marginBottom:20}}/>
			</div>
			<div className="sitemap">
				<h3>Sitio</h3>
				<ul>
					<li><Link to="/">Inicio</Link></li>
					<li><Link to="/#servicios">Servicios</Link></li>
					<li><Link to="/nosotros">Nosotros</Link></li>
					<li><Link to="/contacto">Contacto</Link></li>
				</ul>
			</div>
			
			<div className="contact-details">
				<h3>Nuestra oficina</h3>
                <div className="flex">
                <i className="fas fa-map-marked-alt"></i>
				    <p style={{color:"#fff",textAlign:"left",fontSize:16, marginLeft:10}}>Valencia 114 , Pueblo Libre, Lima - Perú</p>
                </div>
                <div className="flex">
                <i className="fas fa-phone"></i>
				    <p style={{color:"#fff",textAlign:"left",fontSize:16,marginLeft:10}}>941463739</p>
                </div>
                <div className="flex">
                <i className="fas fa-at"></i>
				    <p style={{color:"#fff",textAlign:"left",fontSize:12,marginLeft:10}}>comercial@ppasesoriaintegral.com</p>
                </div>
			</div>	
		</div>
		</div>
  </footer>
)
export default Footer
